// Import the functions you need from the SDKs you need
//import { initializeApp } from "firebase/app";
import { initializeApp } from "firebase/app";
import { getStorage, ref } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getFunctions /*, connectFunctionsEmulator*/ } from 'firebase/functions';


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB7Lw1joSNiCXtjOa9b1P1cU92d2MpjqM0",
  authDomain: "vvobj-9600b.firebaseapp.com",
  projectId: "vvobj-9600b",
  storageBucket: "vvobj-9600b.appspot.com",
  messagingSenderId: "97842530216",
  appId: "1:97842530216:web:370d83207fb0d0e07a3f9d",
  measurementId: "G-012SPQBJH6"
};

const firebaseApp = initializeApp(firebaseConfig);
let firebaseStorage = getStorage(firebaseApp);
let firebaseFirestore = getFirestore(firebaseApp);
let firebaseAuth = getAuth(firebaseApp);
let firebasFunctions = getFunctions(firebaseApp);
//connectFunctionsEmulator(firebasFunctions, "pro.local", 5001);

export {
    firebasFunctions,
    firebaseApp,
    firebaseStorage,
    ref,
    firebaseFirestore,
    firebaseAuth
  }
