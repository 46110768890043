<script setup>




</script>

<template>

    <div class="layout-footer">
    * Adieu Berte *
    </div>
</template>
<style lang="scss" scoped></style>
