<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
import { useStore } from 'vuex'
import { useRouter } from 'vue-router';

const store = useStore();
const router = useRouter();

const email = computed(() => store.getters.user.data.email);
const isLoged= computed(() => store.getters.user.loggedIn);

const outsideClickListener = ref(null);
const topbarMenuActive = ref(false);

const items = ref([
    {
        label: 'Admin Clients',
        icon: 'pi pi-user',
        to: 'dashboard'
    },
    
    {
        separator: true
    },
    {
        label: 'Logout',
        icon: 'pi pi-logout',
        command: async () => {  try {
            await store.dispatch('logOut');
            router.push('/login')
        }
        catch (err) {
            console.log('error : ' + err);
            //error.value = err.message
        } }
    }
]);

onMounted(() => {
    bindOutsideClickListener();

});

onBeforeUnmount(() => {
    unbindOutsideClickListener();
});



const onTopBarMenuButton = () => {
    topbarMenuActive.value = !topbarMenuActive.value;
};

const topbarMenuClasses = computed(() => {
    return {
        'layout-topbar-menu-mobile-active': topbarMenuActive.value
    };
});

const bindOutsideClickListener = () => {
    if (!outsideClickListener.value) {
        outsideClickListener.value = (event) => {
            if (isOutsideClicked(event)) {
                topbarMenuActive.value = false;
            }
        };
        document.addEventListener('click', outsideClickListener.value);
    }
};
const unbindOutsideClickListener = () => {
    if (outsideClickListener.value) {
        document.removeEventListener('click', outsideClickListener);
        outsideClickListener.value = null;
    }
};
const isOutsideClicked = (event) => {
    if (!topbarMenuActive.value) return;

    const sidebarEl = document.querySelector('.layout-topbar-menu');
    const topbarEl = document.querySelector('.layout-topbar-menu-button');

    return !(sidebarEl.isSameNode(event.target) || sidebarEl.contains(event.target) || topbarEl.isSameNode(event.target) || topbarEl.contains(event.target));
};
</script>

<template>
    <div class="layout-topbar surface-200">
       <!-- <button class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle()">
            <i class="pi pi-bars"></i>
        </button>
        
    -->
        <router-link to="/" class="logo-font">
            <span  class="logo-font">Adieu Berthe !</span>
        </router-link>

       

        <button class="p-link layout-topbar-menu-button layout-topbar-button" @click="onTopBarMenuButton()">
            <i class="pi pi-ellipsis-v"></i>
        
        </button>

        <div class="layout-topbar-menu" :class="topbarMenuClasses">
           
            <SplitButton v-if="isLoged" :model="items" class="p-button-text p-button-secondary mb-2">
            <Button>
                <i class="pi pi-user"></i>
                <span class="ml-2 flex align-items-center font-bold">{{email}}</span>
            </Button >
            </SplitButton>
           
        </div>
    </div>
    <Dialog :style="{width: '80%'}" header="Upload" :modal="true" class="p-fluid">
 
        </Dialog>


</template>

<style lang="scss">

:root {
    .logo-font {
        font-family:  'Nanum Pen Script' !important;
        font-size: 3rem;
        color:#434343;
    }
}
</style>