<script setup></script>

<template>
    <router-view />
</template>

<style scoped></style>

<style>
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nanum+Pen+Script&display=swap');

#app {
  font-family: 'Lato', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

</style>
<style lang="scss">

:root {
  --surface-200:#aec2c7!important;
  --orange-ab:#C8655D!important;
  --surface-border:#aec2c7!important;
  --primary-50:#aec2c7!important;
}
</style>