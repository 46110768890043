import { createRouter, createWebHistory } from 'vue-router'
import AdmLayout from '@/layout/AdmLayout.vue';
import { firebaseAuth } from '../firebaseConfig';

const routes = [
  {
    path: '/',
    component: AdmLayout,
    children: [
      {

        path: '',
        name: 'admin_dashboard',
        component: () => import('@/views/DashBoard.vue'),
        meta: { requiresAuth: true },
      },
      {

        path: '/pros',
        name: 'pros_dashboard',
        component: () => import('@/views/ProManager.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/login',
        name: 'login',
        component: () => import('@/views/LoginPage.vue'),
      }
    ],
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]




const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})



router.beforeEach((to, from, next) => {

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  if (requiresAuth) {

    if (!firebaseAuth.currentUser) next({ name: 'login' });
    else {
      firebaseAuth.currentUser.getIdTokenResult()
        .then((idTokenResult) => {
          console.log(idTokenResult.claims);
          if (!idTokenResult.claims.admin) {
            next({ name: 'login' })
          } else {
            next()
          }
        });
    }
  }
  else {
    next();
  }
});

router.beforeResolve((to, from, next) => {

  if (to.name) {
    //       NProgress.start()
  }
  next()
});

router.afterEach(() => {
  //  NProgress.done()
});

export default router
