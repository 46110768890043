import { createStore } from 'vuex'
import {  signOut, createUserWithEmailAndPassword, signInWithEmailAndPassword , updateProfile} from "firebase/auth";
import { firebaseAuth } from '../firebaseConfig';

const state = {
    user: {
        loggedIn:false,
        data: null
    },
    tags: []
}

const mutations = {
    SET_LOGGED_IN(state, value) {
        console.log ("***Set login value***" + value);

        state.user.loggedIn = value;
      },
    SET_USER(state, data) {
        //console.log ('Change State User :' + JSON.stringify( data));
        state.user.data = data;
      },
      SET_TAGS(state, data){
        state.tags = data;
      }
  }

  const actions = {
    register: async (context, { email, password, name}) =>{
        const response = await createUserWithEmailAndPassword(firebaseAuth, email, password)
        if (response) {
            context.commit('SET_USER', response.user)
            await updateProfile(response.user, {displayName: name})
            console.log("auth response:"  + JSON.stringify(response));
            return response.user.uid;
        } else {
            throw new Error('Unable to register user')
        }
    },

    logIn: async (context, { email, password }) => {
      const response = await signInWithEmailAndPassword(firebaseAuth, email, password)
      if (response) {
          context.commit('SET_USER', response.user)
          context.commit('SET_LOGGED_IN', true);
      } else {
          throw new Error('login failed')
      }
  },

  logOut: async (context)=> {
      await signOut(firebaseAuth)
      await context.commit('SET_LOGGED_IN', false);
      await context.commit('SET_USER', null);
  },

  setTagList: (context, data)=> {
    console.log("set tag list : " + data);
    context.commit('SET_TAGS', data)
  },

  fetchUser: async (context ,user) => {
    context.commit("SET_LOGGED_IN", user !== null);
    if (user) {
       context.commit("SET_USER", user);
    } else {
      context.commit("SET_USER", null);
    }
}


/*
    authChange: ({ commit }) => commit('authChange'),
    logme: ({ commit }) => commit('logme'),
    logmeOut: ({ commit }) => commit('logmeOut'),
*/

}

  const getters = {
    user: state => state.user,
    taglist : state => state.taglist
  }


  export default createStore({
    state,
    getters,
    actions,
    mutations
  })
