/* eslint-disable */

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import PrimeVue from 'primevue/config';

import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

import '@/assets/styles.scss';


import FileUpload from 'primevue/fileupload';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import InputText from 'primevue/inputtext';
import Breadcrumb from 'primevue/breadcrumb';
import Dropdown from 'primevue/dropdown';
import ProgressSpinner from 'primevue/progressspinner';
import Dialog from 'primevue/dialog';
import Textarea from 'primevue/textarea';


import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';     //optional for column grouping
import Row from 'primevue/row';                     //optional for row
import Card from 'primevue/card';
import Carousel from 'primevue/carousel';
import Image from 'primevue/image'
import Password from 'primevue/password';

import InputNumber from 'primevue/inputnumber'
import Divider from 'primevue/divider';
import SplitButton from 'primevue/splitbutton';
import Chip from 'primevue/chip';


import { firebaseAuth } from './firebaseConfig';

let app;


firebaseAuth.onAuthStateChanged((user) => {
    if (!app) {
        app = createApp(App);
        app.use(router);
        app.use(PrimeVue);
        app.use(store);
        store.dispatch('fetchUser', user);

        app.component('Button', Button);
        app.component('Breadcrumb', Breadcrumb);
        app.component('FileUpload', FileUpload)
        app.component('InputText', InputText);
        app.component('Checkbox', Checkbox);
        app.component('Dropdown', Dropdown);
        app.component('ProgressSpinner', ProgressSpinner);
        app.component('Dialog', Dialog);
        app.component('Textarea', Textarea);
        app.component('Card', Card);
        app.component('Carousel', Carousel);
        app.component('Image', Image);
        app.component('Password', Password);
        app.component('Divider', Divider);
        app.component('InputNumber', InputNumber);
        app.component('SplitButton', SplitButton);
        app.component('DataTable', DataTable);
        app.component('Column', Column);
        app.component('ColumnGroup', ColumnGroup);
        app.component('Row', Row);
        app.component('Chip', Chip);
        router.isReady().then(() => app.mount('#app'));
    }
});